/*
-----------------------------------------------------------------------
	Divider CSS
-----------------------------------------------------------------------
*/
.divider-area {
	.divider-content-area {
		.content-inner {
			position: relative;
		}
		h2 {
			margin-bottom: 10px;
		}
		p {
			margin-bottom: 0;
		}
		&.divider-content-style1 {
			align-items: center;
			display: flex;
			justify-content: space-between;
			padding: 95px 0 104px;
			@media #{$sm-device} {
				display: block;
				padding: 75px 0 84px;
			}
			.content-inner {
				h2 {
					color: $dark;
					font-size: 48px;
			    font-weight: $font-weight-bold;
			    letter-spacing: .6px;
			    line-height: 1.1;
			    margin-bottom: 22px;
			    @media #{$md-device} {
			    	font-size: 36px;
			    }
			    @media #{$sm-device} {
			    	margin-bottom: 15px;
			    }
			    @media #{$xs-device} {
			    	font-size: 30px;
			    }
			    span {
	    	    color: $dark;
				    font-weight: $font-weight-light;
			    }
				}
				p {
					color: $dark;
					font-size: 16px;
					font-weight: $font-weight-light;
					letter-spacing: -0.15px;
					@media #{$sm-device} {
						margin-bottom: 20px;
					}
				}
			}
			.btn-theme {
				margin-top: 9px;
				&.btn-white {
					color: $theme-color;
					font-size: 18px;
			    font-weight: $font-weight-bold;
			    letter-spacing: -.097px;
			    padding: 19px 47px;
			    text-transform: uppercase;
			    @media #{$sm-device} {
			    	font-size: 14px;
			    	padding: 11px 30px;
			    }
			    &:hover {
			    	color: $dark;
			    }
				}
			}
		}
	}
}

.shape-layer {
	.layer {
		position: absolute;
		&-one {
			bottom: 0;
			left: 231px;
		}
		&-two {
			bottom: 0;
			right: 182px;
		}
		&-three {
			bottom: 0;
			left: 3.4%;
		}
		&-four {
			top: 39%;
			left: 6.8%;
			@media #{$md-device} {
				top: 36.5%;
			}
		}
		&-five {
			top: 39%;
			right: 19.2%;
			@media #{$lg-device} {
				right: 4.2%;
			}
			@media #{$md-device} {
				top: 36.5%;
			}
		}
	}

	&.shape-layer-slyle1 {
		// background-image: url("../img/photos/corporate-mockup-01.jpg");
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		width: 38%;
	}
}