/*
-----------------------------------------------------------------------
	Testimonial CSS
-----------------------------------------------------------------------
*/
.testimonial-area {
	&.testimonial-default-area {
		padding: 145px 0 150px;
		@media #{$lg-device} {
			padding: 98px 0;
		}
		@media #{$sm-device} {
			padding: 78px 0;
		}
		@media #{$xs-device} {
			padding: 68px 0;
		}
		.section-title {
			margin-bottom: 100px;
			@media #{$lg-device} {
				margin-bottom: 75px;
			}
			@media #{$md-device} {
				margin-bottom: 60px;
			}
			@media #{$xs-device} {
				margin-bottom: 50px;
			}
		}
	}
}

.testimonial-item {
	padding: 0;
	position: relative;
	text-align: center;

	.client-content {
		margin: 0 auto 65px;
		max-width: 838px;
		position: relative;
		z-index: 1;
		@media #{$lg-device} {
			margin-bottom: 45px;
		}
		p {
			color: $light;
			font-family: $font-current-theme2;
			font-size: 30px;
			line-height: 48px;
			letter-spacing: -0.7px;
			margin-bottom: 0;
			@media #{$lg-device} {
				font-size: 24px;
				line-height: 38px;
				br {
					display: none;
				}
			}
			@media #{$sm-device} {
				font-size: 16px;
				line-height: 32px;
			}
		}
	}

	.thumb {
		border-radius: 50%;
		margin-bottom: 49px;
		img {
			border-radius: 50%;
		}
	}

	.client-info {
		margin-bottom: 35px;
		position: relative;
		.name {
			color: $theme-color;
			font-size: 24px;
			font-weight: $font-weight-bold;
			letter-spacing: -.25px;
			margin-bottom: 6px;			
			@media #{$sm-device} {
				font-size: 16px;
				font-weight: $font-weight-semi-bold;
			}
		}
		.client-location {
			color: $body-color;
			font-size: 13px;
			letter-spacing: 0;
		}
		.icon-quote {
		    position: absolute;
		    right: 0;
		    transform: translateY(-50%);
		    top: 50%;
			img {
				width: auto;
				@media #{$xxs-device} {
					display: none;
				}
			}
		}
	}
		
	&.line-top {
		border-top: 4px solid $theme-color;
	}
}

.testimonial-slider-container {
	.swiper-button-next,
	.swiper-button-prev {
		color: $light;
		font-size: 60px;
		top: 41.5%;
		@media #{$md-device} {
			top: 28%;
		}
		@media #{$xxs-device} {
			top: 18%;
		}
		@include transition(.3s);
		&:after {
			color: $light;
			content: "\eac9";
			font-family: "IcoFont";
			font-size: 60px;
			@include transition(.3s);
			@media #{$sm-device} {
				font-size: 36px;
			}
		}
		&:hover {
			&:after {
				color: $theme-color;
			}
		}
	}
	.swiper-button-prev {
		left: 5px;
		@media #{$md-device} {
			left: 0;
		}
	}
	.swiper-button-next {
		right: 5px;
		@media #{$md-device} {
			right: 0;
		}
		&:after {
			content: "\eaca";
		}
	}
}